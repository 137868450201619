import { AddressFragment } from '~/graphql';

export const makeAddressLabel = (addresses: AddressFragment[]) => {
  if (addresses.length === 0) {
    return '';
  }

  const { postalCode, prefecture, city, line } = addresses[0];

  return `〒${postalCode} ${prefecture}${city}${line}`;
};
