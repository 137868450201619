import { AppBar, Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

const paths = {
  detail: '',
  organizations: 'organizations',
} as const;

const makePath = (companyId: string, path: (typeof paths)[keyof typeof paths]) => {
  return {
    url: path === paths.detail ? `/companies/[companyId]` : `/companies/[companyId]/${path}`,
    as: path === paths.detail ? `/companies/${companyId}` : `/companies/${companyId}/${path}`,
  };
};

export const CompanyOrganizationTabs: React.FC = () => {
  const router = useRouter();
  const companyId = router.query['companyId'] as string;
  const menu = router.asPath.split('/')[3] ?? '';
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (_: React.ChangeEvent<{}>, value: (typeof paths)[keyof typeof paths]) => {
      const route = makePath(companyId, value);
      router.push(route.url, route.as);
    },
    [router, companyId],
  );

  return (
    <AppBar position="static" color="default">
      <Tabs indicatorColor="primary" onChange={handleChange} value={menu}>
        <Tab label="本部情報" value={paths.detail} />
        <Tab label="店舗一覧" value={paths.organizations} />
      </Tabs>
    </AppBar>
  );
};
