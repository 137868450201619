import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import React from 'react';

type Props = {
  name?: string;
  label: string;
  value?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const Checkbox = (props: Props) => {
  const { onChange } = props;

  return (
    <FormControlLabel
      label={props.label}
      disabled={props.disabled}
      control={
        <MuiCheckbox
          color="primary"
          name={props.name}
          checked={props.checked}
          onChange={onChange}
          value={props.value}
        />
      }
    />
  );
};
