import { ContactPointFragment, ContactPointSystem } from '~/graphql';

export const makeContactPointLabel = (
  contactPoints: ContactPointFragment[],
  system: ContactPointSystem,
) => {
  if (contactPoints.length === 0) {
    return '';
  }

  const contactPoint = contactPoints.filter((c) => c.system === system);

  if (ContactPointSystem.Email === system) {
    return contactPoint[0]?.value ?? '';
  } else {
    return contactPoint[0]?.formattedValue ?? '';
  }
};
