import { ErrorOutline } from '@mui/icons-material';
import { Alert, AlertColor } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  level?: AlertColor;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      whiteSpace: 'pre-wrap',
    },
  }),
);

export const AlertMessae: React.FC<Props> = ({ level = 'error', className, children }) => {
  const classes = useStyles();

  return (
    <Alert color={level} icon={<ErrorOutline />} className={className}>
      <div className={classes.message}>{children}</div>
    </Alert>
  );
};
