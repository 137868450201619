import { InMemoryCache } from '@apollo/client';
import { produce } from 'immer';

import { GetOrganizationBackButtonDocument, MutationResolvers } from '~/graphql';
import { LocalState, ResolverContext } from '~/graphql/types';

import { State } from './types';

export const writeInitialData = (cache: InMemoryCache) => {
  cache.writeQuery<Pick<LocalState, 'organizationBackButton'>>({
    query: GetOrganizationBackButtonDocument,
    data: {
      organizationBackButton: {
        __typename: 'OrganizationBackButton',
        label: null,
        url: null,
        as: null,
      },
    },
  });
};

const setOrganizationBackButton: MutationResolvers<ResolverContext>['setOrganizationBackButton'] = (
  parent,
  args,
  context,
) => {
  const { label, url, as, enabledUrl } = args.input;
  const { cache } = context;

  cache.modify({
    fields: {
      organizationBackButton(state) {
        const nextState = produce(state, (draftState: State) => {
          draftState.label = label;
          draftState.url = url;
          draftState.as = as;
          draftState.enabledUrl = enabledUrl;
        });

        return nextState;
      },
    },
  });

  return null;
};

const resetOrganizationBackButton: MutationResolvers<ResolverContext>['resetOrganizationBackButton'] =
  (parent, args, context) => {
    const { cache } = context;

    cache.modify({
      fields: {
        organizationBackButton(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.label = null;
            draftState.url = null;
            draftState.as = null;
            draftState.enabledUrl = null;
          });

          return nextState;
        },
      },
    });

    return null;
  };

export const Mutation = {
  setOrganizationBackButton,
  resetOrganizationBackButton,
};
