import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  familyName: string;
  givenName: string;
  phoneticFamilyName: string;
  phoneticGivenName: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontWeight: 'bold',
      letterSpacing: '0.08em',
    },
    kana: {
      fontWeight: 'normal',
      letterSpacing: '0.05em',
    },
  }),
);

export const Name = React.memo((props: Props) => {
  const classes = useStyles();

  return (
    <ruby className={classes.root}>
      {props.familyName}
      <rt className={classes.kana}>{props.phoneticFamilyName}</rt>
      {props.givenName}
      <rt className={classes.kana}>{props.phoneticGivenName}</rt>
    </ruby>
  );
});

Name.displayName = 'Name';
