import { Table, TableBody } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

export const DefinitionList: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Table size="small" className={props.className}>
      <TableBody className={classes.root}>{props.children}</TableBody>
    </Table>
  );
};
