import { InMemoryCache } from '@apollo/client';
import { produce } from 'immer';

import { GetSetupDocumentPageDocument, MutationResolvers } from '~/graphql';
import { LocalState, ResolverContext } from '~/graphql/types';

import { State } from './types';

export const writeInitialData = (cache: InMemoryCache) => {
  cache.writeQuery<Pick<LocalState, 'setupDocumentPage'>>({
    query: GetSetupDocumentPageDocument,
    data: {
      setupDocumentPage: {
        __typename: 'SetupDocumentPage',
        newSetupDocumentModal: {
          __typename: 'NewSetupDocumentModal',
          isVisible: false,
        },
      },
    },
  });
};

const setNewSetupDocumentModalVisiblity: MutationResolvers<ResolverContext>['setNewSetupDocumentModalVisiblity'] =
  (parent, args, context) => {
    const { isVisible } = args;
    const { cache } = context;

    cache.modify({
      fields: {
        setupDocumentPage(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.newSetupDocumentModal.isVisible = isVisible;
          });

          return nextState;
        },
      },
    });

    return null;
  };

export const Mutation = {
  setNewSetupDocumentModalVisiblity,
};
