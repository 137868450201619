import { InputAdornment, TextField as MuiTextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  type?: string;
  name?: string;
  value: string;
  error?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const TextField = React.memo((props: Props) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <MuiTextField
      size="small"
      disabled={props.disabled}
      className={classes.root}
      type={props.type}
      fullWidth={props.fullWidth}
      name={props.name}
      label={props.label}
      variant="outlined"
      placeholder={props.placeholder}
      error={!!props.error}
      helperText={props.error}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: props.icon && (
          <InputAdornment position="start">{props.icon}</InputAdornment>
        ),
      }}
      value={props.value}
      onChange={onChange}
    />
  );
});

TextField.displayName = 'TextField';
