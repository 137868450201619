import { AppBar, Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

const paths = {
  detail: '',
  practitioners: 'practitioners',
  clientCerts: 'client_certs',
  systems: 'systems',
} as const;

const makePath = (organizationId: string, path: (typeof paths)[keyof typeof paths]) => {
  return {
    url:
      path === paths.detail
        ? `/organizations/[organizationId]`
        : `/organizations/[organizationId]/${path}`,
    as:
      path === paths.detail
        ? `/organizations/${organizationId}`
        : `/organizations/${organizationId}/${path}`,
  };
};

export const OrganizationTabs: React.FC = () => {
  const router = useRouter();
  const organizationId = router.query['organizationId'] as string;
  const menu = router.asPath.split('/')[3] ?? '';
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (_: React.ChangeEvent<{}>, value: (typeof paths)[keyof typeof paths]) => {
      const route = makePath(organizationId, value);
      router.push(route.url, route.as);
    },
    [router, organizationId],
  );

  return (
    <AppBar position="static" color="default">
      <Tabs indicatorColor="primary" onChange={handleChange} value={menu}>
        <Tab label="店舗情報" value={paths.detail} />
        <Tab label="スタッフ一覧" value={paths.practitioners} />
        <Tab label="クライアント証明書" value={paths.clientCerts} />
        <Tab label="システム連携" value={paths.systems} />
      </Tabs>
    </AppBar>
  );
};
