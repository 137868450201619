import React from 'react';

import { AuthContext } from './context';

type Props = {
  children: React.ReactNode;
  handleError: () => void;
};

export const AuthProvider: React.FC<Props> = (props) => {
  const { handleError, children } = props;
  return <AuthContext.Provider value={{ handleError }}>{children}</AuthContext.Provider>;
};
