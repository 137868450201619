import { Alert, Box } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export const TableEmptyMessage: React.FC<Props> = ({ children }) => {
  return (
    <Box m={1}>
      <Alert variant="standard" severity="info">
        {children || <>条件に一致する情報はありません</>}
      </Alert>
    </Box>
  );
};
