import { AppBar, Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

const paths = {
  setup_documents: 'setup_documents',
  completed: 'completed',
} as const;

const makePath = (path: (typeof paths)[keyof typeof paths]) => {
  return path === paths.setup_documents
    ? `/${paths.setup_documents}`
    : `/${paths.setup_documents}/${path}`;
};

export const SetupDocumentsTabs = () => {
  const router = useRouter();
  const menu = router.pathname.split('/')[2] || 'setup_documents';

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (_: React.ChangeEvent<{}>, value: (typeof paths)[keyof typeof paths]) => {
      router.push(makePath(value));
    },
    [router],
  );

  return (
    <AppBar position="static" color="default">
      <Tabs indicatorColor="primary" value={menu} onChange={handleChange}>
        <Tab label="未セットアップ" value={paths.setup_documents} />
        <Tab label="セットアップ済" value={paths.completed} />
      </Tabs>
    </AppBar>
  );
};
