import { InMemoryCache } from '@apollo/client';
import { produce } from 'immer';

import { GetOperatorsPageDocument, MutationResolvers } from '~/graphql';
import { LocalState, ResolverContext } from '~/graphql/types';

import { State } from './types';

export const writeInitialData = (cache: InMemoryCache) => {
  cache.writeQuery<Pick<LocalState, 'operatorsPage'>>({
    query: GetOperatorsPageDocument,
    data: {
      operatorsPage: {
        __typename: 'OperatorsPage',
        deleteOperatorModal: {
          __typename: 'DeleteOperatorModal',
          isVisible: false,
          operatorId: null,
        },
      },
    },
  });
};

const setDeleteOperatorModalVisiblity: MutationResolvers<ResolverContext>['setDeleteOperatorModalVisiblity'] =
  (parent, args, context) => {
    const { isVisible, operatorId } = args;
    const { cache } = context;

    cache.modify({
      fields: {
        operatorsPage(state) {
          return produce(state, (draftState: State) => {
            draftState.deleteOperatorModal.isVisible = isVisible;
            draftState.deleteOperatorModal.operatorId = operatorId || null;
          });
        },
      },
    });

    return null;
  };

export const Mutation = {
  setDeleteOperatorModalVisiblity,
};
