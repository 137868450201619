import { Assignment, AssignmentTurnedIn } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ClipboardJS from 'clipboard';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  className?: string;
  text: string;
};

export const ClipButton = React.memo((props: Props) => {
  const [isCliped, setIsCliped] = useState(false);
  const btnCopyRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!btnCopyRef.current) return;

    const btnElem = btnCopyRef.current;
    const clipboard = new ClipboardJS(btnElem);
    let timerId: number | undefined = undefined;

    clipboard.on('success', () => {
      clearTimeout(timerId);
      setIsCliped(true);
      timerId = window.setTimeout(() => {
        setIsCliped(false);
      }, 1000 * 2);
    });

    return () => {
      clearTimeout(timerId);
      clipboard.destroy();
    };
  }, []);

  return (
    <IconButton
      ref={btnCopyRef}
      size="small"
      className={props.className}
      data-clipboard-text={props.text}
    >
      {isCliped ? <AssignmentTurnedIn fontSize="small" /> : <Assignment fontSize="small" />}
    </IconButton>
  );
});

ClipButton.displayName = 'ClipButton';
