export const getApiOperators = (token: string): Promise<boolean> =>
  fetch(`${process.env.apiBaseUrl}/operators`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.status === 200);

export const postApiOperators = (idToken: string): Promise<string> =>
  fetch(`${process.env.apiBaseUrl}/operators`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: idToken }),
  })
    .then((response) => response.json())
    .then((json) => json.jwt);

export const getApiDummyOperators = (): Promise<string> =>
  fetch(`${process.env.apiBaseUrl}/dummy_operators`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => json.jwt);
