import { FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useRef } from 'react';

type Props = {
  label?: string;
  helperText?: string;
  error?: string;
  children?: React.ReactNode;
  onChange?: (event: React.FormEvent<HTMLDivElement>, value: string[]) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);

export const CheckboxGroup: React.FC<Props> = (props) => {
  const classses = useStyles();
  const groupRef = useRef<HTMLDivElement>();
  const { onChange } = props;
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      if (!groupRef.current) {
        return;
      }

      const checkedElements = groupRef.current.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"]:checked',
      );
      const values: string[] = [];

      for (const element of checkedElements) {
        values.push(element.value);
      }

      onChange && onChange(event, values);
    },
    [onChange],
  );

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      className={classses.root}
      error={!!props.error}
    >
      {props.label && <FormLabel component="legend">{props.label}</FormLabel>}
      <FormGroup row ref={groupRef} onChange={handleChange}>
        {props.children}
      </FormGroup>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};
