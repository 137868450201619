import { TextField as MuiTextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  name: string;
  value: string;
  error?: string;
  helperText?: string;
  disabled?: boolean;
  rows?: number;
  onChange: (e: React.ChangeEvent<any>) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      resize: 'vertical',
    },
  }),
);

export const TextArea = React.memo((props: Props) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <MuiTextField
      size="small"
      multiline
      inputProps={{
        className: classes.root,
      }}
      fullWidth={props.fullWidth}
      variant="outlined"
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      error={!!props.error}
      helperText={props.error ?? props.helperText}
      InputLabelProps={{
        shrink: true,
      }}
      minRows={props.rows ?? 5}
      value={props.value}
      onChange={onChange}
      disabled={!!props.disabled}
    />
  );
});

TextArea.displayName = 'TextArea';
