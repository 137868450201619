import 'typeface-roboto';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import NextApp from 'next/app';
import NextHead from 'next/head';
import React, { ErrorInfo } from 'react';
import { RecoilRoot } from 'recoil';

import { Authn, PageLoadProgress } from '~/components/partials';
import { DummyAuthn } from '~/components/partials/DummyAuthn';
import { client } from '~/graphql/apollo_client';
import { theme } from '~/styles/theme';
import { publicPath } from '~/utils/path';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

class App extends NextApp {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo);
      });
      Sentry.captureException(error);
    });
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;

    return (
      <Sentry.ErrorBoundary>
        <NextHead>
          <title>Pharms（ファームス） 社内管理ツール</title>
          <link rel="icon" href={`${publicPath('/favicon.ico')}`}></link>
        </NextHead>

        <RecoilRoot>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <PageLoadProgress />
                <DummyAuthn>
                  <Authn>
                    <Component {...pageProps} />
                  </Authn>
                </DummyAuthn>
              </ThemeProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </RecoilRoot>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
