import { TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  term: React.ReactNode;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    term: {
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      wordBreak: 'keep-all',
      textAlign: 'left',
      color: theme.palette.text.secondary,
      fontSize: '0.7rem',
      padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    description: {
      width: '100%',
      borderBottom: 'none',
      fontSize: '1rem',
      padding: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
      whiteSpace: 'pre-wrap',
    },
  }),
);

export const DefinitionItem: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableRow hover className={classes.root}>
      <TableCell component="th" scope="row" align="right" className={classes.term}>
        {props.term}
      </TableCell>
      <TableCell className={classes.description}>{props.children}</TableCell>
    </TableRow>
  );
};
