import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { clsx } from 'clsx';
import React from 'react';

type Props = {
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode[];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      background: theme.palette.background.paper,
    },
    progress: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
    head: {
      background: theme.palette.background.default,
    },
  }),
);

export const ExpandTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <TableContainer className={clsx(classes.root, props.className)}>
      {props.loading && <LinearProgress className={classes.progress} />}
      <Table size="small">
        {props.title && props.title.length > 0 && (
          <TableHead className={clsx(classes.head, props.className)}>
            <TableRow>
              <TableCell />
              {props.title.map((t, idx) => (
                <TableCell key={idx}>{t}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
};
