const addBuildId = (url: string) => {
  const buildId = process.env.buildId;

  return `${url}${buildId ? `?${encodeURIComponent(buildId)}` : ''}`;
};

export const publicPath = (path: string) => {
  return addBuildId(`${process.env.publicPrefix}${path}`);
};

export const pharmsPath = (path: string) => {
  return `${process.env.pharmsRootUrl}${path}`;
};

export const pharmsClientAuthPath = (path: string) => {
  return `${process.env.pharmsSUrl}${path}`;
};
