import { FormControl, FormHelperText, FormLabel, RadioGroup as MuiRadioGroup } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  name: string;
  label?: string;
  helperText?: string;
  value: string | number;
  error?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const RadioGroup: React.FC<Props> = (props) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={!!props.error}
      disabled={props.disabled}
      className={classes.root}
    >
      {props.label && <FormLabel component="legend">{props.label}</FormLabel>}
      <MuiRadioGroup row name={props.name} value={props.value} onChange={onChange}>
        {props.children}
      </MuiRadioGroup>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};
