import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { clsx } from 'clsx';
import React from 'react';

import { publicPath } from '~/utils/path';

type Props = {
  label?: string;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      textAlign: 'center',
    },
    img: {
      width: 355,
      height: 100,
    },
    label: {
      margin: theme.spacing(1, 'auto', 0),
      fontSize: theme.typography.fontSize,
      textAlign: 'center',
    },
  }),
);

export const Logo = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <img src={publicPath('/logo.svg')} className={classes.img} />
      {props.label && <p className={classes.label}>{props.label}</p>}
    </div>
  );
};
