import { Link as MuiLink } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

type Props = {
  url: string;
  as?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
  }),
);

export const Link: React.FC<Props> = React.memo((props) => {
  const { onClick } = props;
  const router = useRouter();
  const classes = useStyles();
  const handleClick = useCallback(() => {
    onClick && onClick();
    router.push(props.url, props.as);
  }, [onClick, router, props.url, props.as]);

  return (
    <MuiLink className={classes.root} onClick={handleClick} underline="hover">
      {props.children}
    </MuiLink>
  );
});

Link.displayName = 'Link';
