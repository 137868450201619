import { FormControlLabel, Radio as MuiRadio } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  value: string | number;
};

export const Radio = (props: Props) => {
  return (
    <FormControlLabel
      value={props.value}
      control={<MuiRadio color="primary" />}
      label={props.label}
    />
  );
};
