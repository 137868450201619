import { CredentialResponse } from 'google-one-tap';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { getApiOperators, postApiOperators } from '~/hooks/use-auth';
import { deleteToken, getToken, setToken } from '~/utils/token';

import { AuthProvider } from '../AuthProvider';

type Props = {
  children: React.ReactNode;
};

export const Authn = ({ children }: Props) => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleCallback = useCallback((response: CredentialResponse) => {
    postApiOperators(response.credential).then((jwt) => {
      setToken(jwt);
      setIsLoaded(true);
    });
  }, []);

  const handleError = useCallback(() => {
    deleteToken();
    setIsLoaded(false);
    router.reload();
  }, [router]);

  useEffect(() => {
    const authToken = getToken();
    const validToken = authToken ? getApiOperators(authToken) : Promise.resolve(false);
    validToken.then((result) => {
      if (result) {
        setIsLoaded(true);
      } else {
        const clientId = process.env.googleClientId;
        if (!clientId) return;

        google.accounts.id.initialize({
          client_id: clientId,
          auto_select: true,
          callback: handleCallback,
        });
        google.accounts.id.prompt();
      }
    });
  }, [handleCallback]);

  return (
    <>
      <Head>
        <script src="https://accounts.google.com/gsi/client?hl=ja" />
      </Head>
      {isLoaded && <AuthProvider handleError={handleError}>{children}</AuthProvider>}
    </>
  );
};
