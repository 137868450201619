import { InMemoryCache } from '@apollo/client';
import { produce } from 'immer';

import { GetOperatorRolesPageDocument, MutationResolvers } from '~/graphql';
import { LocalState, ResolverContext } from '~/graphql/types';

import { State } from './types';

export const writeInitialData = (cache: InMemoryCache) => {
  cache.writeQuery<Pick<LocalState, 'operatorRolesPage'>>({
    query: GetOperatorRolesPageDocument,
    data: {
      operatorRolesPage: {
        __typename: 'OperatorRolesPage',
        newOperatorRoleModal: {
          __typename: 'NewOperatorRoleModal',
          isVisible: false,
        },
        deleteOperatorRoleModal: {
          __typename: 'DeleteOperatorRoleModal',
          isVisible: false,
          operatorRoleId: null,
        },
      },
    },
  });
};

const setNewOperatorRoleModalVisiblity: MutationResolvers<ResolverContext>['setNewOperatorRoleModalVisiblity'] =
  (parent, args, context) => {
    const { isVisible } = args;
    const { cache } = context;

    cache.modify({
      fields: {
        operatorRolesPage(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.newOperatorRoleModal.isVisible = isVisible;
          });

          return nextState;
        },
      },
    });

    return null;
  };

const setDeleteOperatorRoleModalVisiblity: MutationResolvers<ResolverContext>['setDeleteOperatorRoleModalVisiblity'] =
  (parent, args, context) => {
    const { isVisible, operatorRoleId } = args;
    const { cache } = context;

    cache.modify({
      fields: {
        operatorRolesPage(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.deleteOperatorRoleModal.isVisible = isVisible;
            draftState.deleteOperatorRoleModal.operatorRoleId = operatorRoleId || null;
          });

          return nextState;
        },
      },
    });

    return null;
  };

export const Mutation = {
  setNewOperatorRoleModalVisiblity,
  setDeleteOperatorRoleModalVisiblity,
};
