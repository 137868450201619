import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import React from 'react';

import { Select } from '~/components/blocks';

import { usePlanOptions } from './use-plan_options';

export type PlanProps = {
  name?: string | undefined;
  value: unknown;
};

type Props = {
  disabled?: boolean;
  label?: string;
  name?: string;
  value: string;
  error?: string;
  onChange: (event: SelectChangeEvent<string | number>) => void;
};

export const SelectPlan = React.memo((props: Props) => {
  const options = usePlanOptions();

  return (
    <Select
      label={props.label || 'プラン'}
      name={props.name || 'planId'}
      disabled={props.disabled}
      options={options}
      value={props.value}
      error={props.error}
      onChange={props.onChange}
    />
  );
});

SelectPlan.displayName = 'SelectPlan';
