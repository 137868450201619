import { LinearProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Router } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: theme.zIndex.modal,
      '& > .MuiLinearProgress-bar': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
);

export const PageLoadProgress = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleStart = useCallback(() => {
    setLoading(true);
  }, []);
  const handleComplete = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleComplete);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleComplete);
    };
  }, [handleComplete, handleStart]);

  return loading ? <LinearProgress className={classes.root} /> : null;
};
