import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  items: React.ReactNode[];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transition: '0.2s',
      '& > *': {
        borderBottom: 'unset',
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover,
      },
    },
    expandCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    expandIcon: {
      width: '1px',
    },
  }),
);

export const ExpandTableRow: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const colSpan = props.items.length + 1;

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell className={classes.expandIcon}>
          <IconButton size="small">{open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
        </TableCell>
        {props.items.map((i, idx) => (
          <TableCell key={idx} scope="row">
            {i}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandCell} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{props.children}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
