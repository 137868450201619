import { InMemoryCache } from '@apollo/client';

import * as AnnouncementsPage from '~/components/pages/announcements/state';
import * as OperatorRolesPage from '~/components/pages/operator_roles/state';
import * as OperatorPage from '~/components/pages/operators/state';
import * as SetupDocumentsPage from '~/components/pages/setup_documents/state';
import * as OrganizationBackButton from '~/components/partials/OrganizationBackButton/state';
import { Resolvers } from '~/graphql';

export const resolvers: Resolvers = {
  Mutation: {
    // コンポーネント
    ...OrganizationBackButton.Mutation,

    // ページ
    ...AnnouncementsPage.Mutation,
    ...OperatorRolesPage.Mutation,
    ...OperatorPage.Mutation,
    ...SetupDocumentsPage.Mutation,
  },
};

const writeInitialDataFuncs = [
  // コンポーネント
  OrganizationBackButton.writeInitialData,

  // ページ
  AnnouncementsPage.writeInitialData,
  OperatorRolesPage.writeInitialData,
  OperatorPage.writeInitialData,
  SetupDocumentsPage.writeInitialData,
];

export const writeLocalInitialData = (cache: InMemoryCache) => {
  for (const writeInitialDataFunc of writeInitialDataFuncs) {
    writeInitialDataFunc(cache);
  }
};
