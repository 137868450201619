import { useMemo } from 'react';

import { PlanFeatureCodeLabel } from '~/constants/plan_feature';
import { useGetSelectablePlansQuery } from '~/graphql';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 50;

export const usePlanOptions = () => {
  const { data } = useGetSelectablePlansQuery({
    variables: {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    },
  });

  const options = useMemo(() => {
    if (!data) return [];

    return data.plans.nodes.map(({ id, name, features }) => {
      const featureList = features.map(({ code }) => PlanFeatureCodeLabel[code]).join('/');
      return {
        value: id,
        label: `${name}(${featureList})`,
      };
    });
  }, [data]);

  return options;
};
