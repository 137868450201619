import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

// import { onError } from '@apollo/link-error';
import { resolvers, writeLocalInitialData } from './local_settings';

const httpLink = new HttpLink({
  uri: `${process.env.apiBaseUrl}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext(({ headers }: Record<string, any>) => ({
    headers: {
      ...headers,
      credentials: 'include',
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  ssrMode: false,
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  headers: {},
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
  },
  resolvers,
  connectToDevTools: true,
});

if (process.browser) {
  // state初期化
  client.onResetStore(() => {
    writeLocalInitialData(cache);
    return Promise.resolve();
  });
  writeLocalInitialData(cache);
}

export { client };
