import { AppBar, Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

const paths = {
  operators: 'operators',
  roles: 'roles',
} as const;

const makePath = (path: (typeof paths)[keyof typeof paths]) => {
  return path === paths.operators ? `/${paths.operators}` : `/${paths.operators}/${path}`;
};

export const OperatorsTabs: React.FC = () => {
  const router = useRouter();

  const menu = router.pathname.split('/')[2] || 'operators';

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (_: React.ChangeEvent<{}>, value: (typeof paths)[keyof typeof paths]) => {
      router.push(makePath(value));
    },
    [router],
  );

  return (
    <AppBar position="static" color="default">
      <Tabs indicatorColor="primary" onChange={handleChange} value={menu}>
        <Tab label="オペレータ一覧" value={paths.operators} />
        <Tab label="役割" value={paths.roles} />
      </Tabs>
    </AppBar>
  );
};
