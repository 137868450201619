import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { getApiDummyOperators } from '~/hooks/use-auth';
import { setToken } from '~/utils/token';

type Props = {
  children: React.ReactNode;
};

export const DummyAuthn = ({ children }: Props) => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (router.pathname !== '/dummy_login') return;

    setIsLoaded(true);
    getApiDummyOperators().then((jwt) => {
      setToken(jwt);
      setIsLoaded(false);
    });
  }, [router]);

  return <>{!isLoaded && children}</>;
};
