import { ArrowBackIos } from '@mui/icons-material';
import { Fab, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import {
  useGetOrganizationBackButtonQuery,
  useResetOrganizationBackButtonMutation,
} from '~/graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(3),
      left: 264,
    },
  }),
);

export const OrganizationBackButton = () => {
  const classes = useStyles();
  const router = useRouter();
  const { data } = useGetOrganizationBackButtonQuery();
  const [reset] = useResetOrganizationBackButtonMutation();
  const label = data?.organizationBackButton.label;
  const url = data?.organizationBackButton.url;
  const as = data?.organizationBackButton.as || undefined;
  const enabledUrl = data?.organizationBackButton.enabledUrl;
  const isAvailable = url && enabledUrl && router.asPath.indexOf(enabledUrl) >= 0;
  const handleClick = useCallback(() => {
    reset();
    url && router.push(url, as);
  }, [as, reset, router, url]);

  useEffect(() => {
    if (!isAvailable) {
      reset();
    }
  }, [isAvailable, reset]);

  if (!isAvailable) {
    return null;
  }

  return (
    <Fab size="small" variant="extended" className={classes.root} onClick={handleClick}>
      <ArrowBackIos />
      {label}
    </Fab>
  );
};
