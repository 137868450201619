import { AccountCircle } from '@mui/icons-material';
import { AppBar, IconButton, Menu, MenuItem, Theme, Toolbar, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { useGetSignedinOperatorQuery } from '~/graphql';
import { publicPath } from '~/utils/path';
import { deleteToken } from '~/utils/token';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      fontSize: '0.9rem',
    },
    logo: {
      height: '32px',
      verticalAlign: 'bottom',
      marginRight: theme.spacing(2),
    },
  }),
);

export const AppHeader = () => {
  const classes = useStyles();
  const router = useRouter();
  const { data } = useGetSignedinOperatorQuery({ fetchPolicy: 'cache-first' });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const account = data?.me?.__typename === 'Operator' ? data.me.email : '';
  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleSignout = useCallback(() => {
    setAnchorEl(null);
    deleteToken();
    router.push('login');
  }, [router]);

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <img src={publicPath('/logo-white.svg')} className={classes.logo} />
          社内管理ツール
        </Typography>
        <div>
          {account}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleSignout}>ログアウト</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
