import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { theme } from '~/styles/theme';

type Props = {
  name: string;
  label: string;
  value: string | number;
  error?: string;
  disabled?: boolean;
  options: { value: string | number; label: string }[];
  onChange: (event: SelectChangeEvent<string | number>, child: React.ReactNode) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const Select = (props: Props) => {
  const { onChange } = props;
  const classes = useStyles();

  return (
    <FormControl
      size="small"
      variant="outlined"
      error={!!props.error}
      disabled={props.disabled}
      className={classes.root}
    >
      <InputLabel shrink>{props.label}</InputLabel>
      <MuiSelect
        variant="outlined"
        native
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={onChange}
        style={{
          background: props.disabled ? theme.palette.grey[100] : '#ffffff',
        }}
      >
        <option value="" />
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </MuiSelect>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};
