import { Chip } from '@mui/material';
import React from 'react';

import { SendgridEventActivityStatus } from '~/graphql';

type Props = {
  status?: SendgridEventActivityStatus;
};

const makeChipStyle = (status: SendgridEventActivityStatus) => {
  let label = '';
  let color: 'primary' | 'secondary' | 'default' | undefined = 'default';
  let variant: 'filled' | 'outlined' | undefined = 'filled';

  switch (status) {
    case 'failed':
      label = '送信失敗';
      color = 'secondary';
      break;
    case 'pending':
      label = '送信準備中';
      break;
    case 'processing':
      label = '送信中';
      variant = 'outlined';
      break;
    case 'successed':
      label = '送信完了';
      color = 'primary';
      break;
  }

  return { color, label, variant };
};

export const MailStatus = (props: Props) => {
  const { status } = props;
  const chipStyle = status && makeChipStyle(status);

  return chipStyle ? (
    <Chip
      size="small"
      label={chipStyle.label}
      color={chipStyle.color}
      variant={chipStyle.variant}
    />
  ) : (
    <div>ー</div>
  );
};
