import { Box, Skeleton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';

import { DefinitionItem, DefinitionList, Link } from '~/components/blocks';
import { OrganizationFragment } from '~/graphql';
import { makeAddressLabel } from '~/utils/address';
import { makeContactPointLabel } from '~/utils/contact_point';

import { DeletedStatus } from '../DeletedStatus';

type Props = {
  loading?: boolean;
  organization?: OrganizationFragment;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    organization: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: 'min-content 1fr',
      gridGap: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
    },
  }),
);

export const OrganizationInfo = (props: Props) => {
  const classes = useStyles();
  const profile = useMemo(() => {
    const addresses = props.organization?.organizationAddresses.map((a) => a.address) || [];
    const contactPoinstc =
      props.organization?.organizationContactPoints.map((c) => c.contactPoint) || [];

    return {
      code: props.organization?.slug,
      companyId: props.organization?.company.id,
      companyName: props.organization?.company.name,
      name: props.organization?.name || '未設定',
      address: makeAddressLabel(addresses) || '未設定',
      tel: makeContactPointLabel(contactPoinstc, 'phone') || '未設定',
      fax: makeContactPointLabel(contactPoinstc, 'fax') || '未設定',
      icon: props.organization?.webBookingsSetting?.logo
        ? props.organization?.webBookingsSetting?.logo?.url
        : process.env.defaultLogoPath,
      hasDeleted: !props.organization?.active,
      hasDeletedCompany: !props.organization?.company.active,
    };
  }, [props.organization]);

  return props.loading ? (
    <Box className={classes.organization}>
      <Skeleton variant="rectangular" width={240} height={240} />
      <DefinitionList>
        <DefinitionItem term="店舗コード">
          <Skeleton />
        </DefinitionItem>
        <DefinitionItem term="店舗名">
          <Skeleton />
        </DefinitionItem>
        <DefinitionItem term="住所">
          <Skeleton />
        </DefinitionItem>
        <DefinitionItem term="電話番号">
          <Skeleton />
        </DefinitionItem>
        <DefinitionItem term="FAX">
          <Skeleton />
        </DefinitionItem>
        <DefinitionItem term="本部">
          <Skeleton />
        </DefinitionItem>
      </DefinitionList>
    </Box>
  ) : (
    <Box className={classes.organization}>
      <img width="240" src={profile.icon} />
      <DefinitionList>
        <DefinitionItem term="店舗コード">{profile.code}</DefinitionItem>
        <DefinitionItem term="店舗名">
          {profile.name}
          {profile.hasDeleted && <DeletedStatus />}
        </DefinitionItem>
        <DefinitionItem term="住所">{profile.address}</DefinitionItem>
        <DefinitionItem term="電話番号">{profile.tel}</DefinitionItem>
        <DefinitionItem term="FAX">{profile.fax}</DefinitionItem>
        <DefinitionItem term="本部">
          <Link url={'/companies/[companyId]'} as={`/companies/${profile.companyId}`}>
            {profile.companyName}
            {profile.hasDeletedCompany && <DeletedStatus />}
          </Link>
        </DefinitionItem>
      </DefinitionList>
    </Box>
  );
};
