import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#456',
            color: '#FFF',
            ['&:hover']: {
              backgroundColor: '#789',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            backgroundColor: '#3DA466',
            color: '#FFF',
            ['&:hover']: {
              backgroundColor: 'rgb(42, 114, 71)',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            color: '#223344',
            ['&:hover']: {
              border: '1px solid rgba(0, 0, 0, 0.23)',
              backgroundColor: 'rgba(34, 51, 68, 0.04)',
              textDecoration: 'none',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: '#223344',
            ['&:hover']: {
              backgroundColor: 'rgba(34, 51, 68, 0.04)',
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          minWidth: '4rem',
          textAlign: 'center',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#FFF',
          borderBottom: '1px solid #D2D7E1',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
          paddingBottom: '16px',
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
          paddingBottom: '16px',
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: 'none',
        },
        outlined: {
          borderColor: '#D2D7E1',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: '1px solid #D2D7E1',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#D2D7E1',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #D2D7E1',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '6px 12px',
          maxWidth: '264px',
          minWidth: '72px',
          '&.Mui-selected': {
            color: 'inherit',
          },
          '@media (min-width: 600px)': {
            minWidth: '160px',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3DA466',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#E94781',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#223344',
      secondary: '#778899',
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    subtitle1: {
      fontSize: '0.7rem',
    },
  },
});
