import { Chip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    style: {
      marginLeft: theme.spacing(2),
    },
  }),
);

export const DeletedStatus = () => {
  const classes = useStyles();

  return <Chip className={classes.style} size="small" label="削除済み" />;
};
