import { InMemoryCache } from '@apollo/client';
import { produce } from 'immer';

import { GetAnnouncementsPageDocument, MutationResolvers } from '~/graphql';
import { LocalState, ResolverContext } from '~/graphql/types';

import { State } from './types';

export const writeInitialData = (cache: InMemoryCache) => {
  cache.writeQuery<Pick<LocalState, 'announcementsPage'>>({
    query: GetAnnouncementsPageDocument,
    data: {
      announcementsPage: {
        __typename: 'AnnouncementsPage',
        editAnnouncementModal: {
          __typename: 'EditAnnouncementModal',
          isVisible: false,
          announcementId: null,
        },
        newAnnouncementModal: {
          __typename: 'NewAnnouncementModal',
          isVisible: false,
        },
      },
    },
  });
};

const setEditAnnouncementModalVisiblity: MutationResolvers<ResolverContext>['setEditAnnouncementModalVisiblity'] =
  (parent, args, { cache }) => {
    const { isVisible, announcementId = null } = args;

    cache.modify({
      fields: {
        announcementsPage(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.editAnnouncementModal.isVisible = isVisible;
            draftState.editAnnouncementModal.announcementId = announcementId;
          });

          return nextState;
        },
      },
    });

    return null;
  };

const setNewAnnouncementModalVisiblity: MutationResolvers<ResolverContext>['setNewAnnouncementModalVisiblity'] =
  (parent, args, { cache }) => {
    const { isVisible } = args;

    cache.modify({
      fields: {
        announcementsPage(state) {
          const nextState = produce(state, (draftState: State) => {
            draftState.newAnnouncementModal.isVisible = isVisible;
          });

          return nextState;
        },
      },
    });

    return null;
  };

export const Mutation = {
  setEditAnnouncementModalVisiblity,
  setNewAnnouncementModalVisiblity,
};
